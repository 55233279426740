import '@mountainpass/react-pokemon-cards/dist/css/cards.css'
import { useEffect, useState } from 'react';
import { getData } from '../services/service';
import { Alert, Box, Button, Card, CardContent, CircularProgress, Fade, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import CardItem from './CardItem';
import { OpenInNew, Star } from '@mui/icons-material';
import PriceStatus from './PriceStatus';

function PokemonItems() {
  //var rarities = ['custom','common','radiant rare','rare holo','rare holo galaxy','rare holo v','rare holo vmax','rare holo vstar','rare rainbow','rare rainbow alt','rare secret','rare ultra','uncommon'];
  //var subtypes = ['basic','basic v','basic v fusion strike','basic v rapid strike','basic v single strike','item','pokémon tool','stadium','stage 1','stage 2','stage 2 rapid strike','supporter','vmax','vmax rapid strike','vmax single strike','vstar'];
  const [ cards, setCards ] = useState([]);
  const [ stickers, setStickers ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ alert, setAlert ] = useState(false);
  const [ alertText, setAlertText ] = useState("");
  const [ alertCollapse, setAlertCollapse ] = useState(false);
  const [ favorite, setFavorite ] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getData(favorite);
        setCards(data.cards);
        setStickers(data.stickers);
      } catch (error) {
        setAlert(true);
        setAlertText(error.message);
        setAlertCollapse(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [favorite]);

  return (
    <>
      {loading && 
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: '100vh'
        }}
      >
        <CircularProgress color='warning' size={200}/>
      </div>}
      {!loading && 
        <Grid container spacing={6} my={5}>
          {alert &&
            <Fade in={alertCollapse}>
              <Alert variant="filled" severity="error" sx={{ mb: 2, width: '100%' }}>
                {alertText}
              </Alert>
            </Fade>
          }
          {
            <Paper style={{ position: 'fixed', top: '1.45rem', left: '1.45rem', zIndex: 9999, padding: '0.5rem' }}>
              <IconButton color={favorite ? 'warning' : ''} onClick={() => setFavorite(!favorite)} >
                <Star />
              </IconButton>
            </Paper>
          }
          {cards && cards.map(function(card) {
            return (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <CardItem src={card.cardImageUrl} rarity={card.rarityInternal} />
                <Card sx={{ borderBottom: "5px solid", borderColor: card.favorite === '1' ? "warning.main" : "transparent" }}>
                  <CardContent>
                    <Box p={1} display="flex" justifyContent="center" alignItems="center">
                      <img alt={card.setName} src={card.setLogo} style={{ maxHeight: '45px' }} />
                    </Box>
                    <Typography sx={{ color: 'warning.main', display: 'flex', alignItems: 'center', justifyContent: 'center', }} p={1} variant="h6" component="div" align="center">
                      {card.price} € <PriceStatus status={card.priceStatus} />
                    </Typography>
                    <Typography pb={1} variant="p" component="div" align="center">
                    {card.rarity} - {card.raritySub}
                    </Typography>
                    <Stack spacing={1} direction="row" display="flex" justifyContent="center" alignItems="center">
                      {card.tcgPlayerUrl && <Button href={card.tcgPlayerUrl} size="small" target="_blank" variant="contained" color="primary" endIcon={<OpenInNew />}>TCG</Button>}
                      {card.cardMarketUrl && <Button href={card.cardMarketUrl} size="small" target="_blank" variant="contained" color="info" endIcon={<OpenInNew />}>CM</Button>}
                      {card?.priceChartingUrl && <Button href={card.priceChartingUrl} size="small" target="_blank" variant="contained" color="success" endIcon={<OpenInNew />}>PCh</Button>}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
          {stickers && stickers.map(function(sticker) {
            return (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <CardItem src={sticker.stickerImageUrl} />
                <Card sx={{ borderBottom: "5px solid", borderColor: sticker.favorite === '1' ? "warning.main" : "transparent" }}>
                  <CardContent>
                    <Box p={1} display="flex" justifyContent="center" alignItems="center">
                      <img alt={sticker.setName} src={sticker.setLogo} style={{ maxHeight: '65px' }} />
                    </Box>
                    <Stack spacing={1} direction="row" display="flex" justifyContent="center" alignItems="center">
                      {sticker.ebayUrl && <Button href={sticker.ebayUrl} size="small" target="_blank" variant="contained" color="warning" endIcon={<OpenInNew />}>eBay</Button>}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      }
    </>
  )
}

export default PokemonItems;