import axios from "axios";

export const getData = async (_favorite) => {
  const favorite = Number(_favorite);
  try {
    const response = await axios.post('https://pokemon.marianmzigot.com/backend/getData.php?favorite=' + favorite);
    const data = response.data;
    return data;
  } catch(error) {
    throw new Error('Error fetching data: ' + error.message);
  }
}